import React,{useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from 'react-bootstrap/Button';
import MultiSelect from "../../commom/MultiSelect";

const SiteForm = (props) => {
       const validVolumes = ['>3M', '1M - 3M', '500K - 999K', '100K - 499K', '<100K', ''];
    const handleAnnualVolumeChange = (e) => {
        const selectedValue = e.target.value; 
        if (selectedValue === ">3M") {
                props.handleChange(e,'>3M');   
        }else if(selectedValue === "1M - 3M"){
            props.handleChange(e,'1M - 3M');
        }else if(selectedValue === "500K - 999K"){
            props.handleChange(e,'500K - 999K');
        }else if(selectedValue === "100K - 499K"){
            props.handleChange(e,'100K - 499K');            
        }
        else{
            props.handleChange(e,false);
        }
      };

    return (
        <form onSubmit={props.submitHandler} className="formContainer w-100 p-3" autoComplete="off">
            <Row>
                <div className="form-group col">
                    <label className="col-form-label" htmlFor="plant"><span className="text-danger starCss">*</span>Plant</label>
                    <div>
                        <select onChange={props.handleChange} name="plant"
                                className={`form-select ${!props.inputValidation.plant ? "errorDiv" : ""}`}
                                value={props.formValue.plant} disabled={(!props.isAdminAccess && props.readOnlyField)}>
                            <option value="select">Select</option>
                            {
                                props.plantData && props.plantData.length
                                && props.plantData.map((obj, index) => {
                                    return <option key={index + obj._id} value={obj._id}>{obj._id}</option>
                                })
                            }

                        </select>
                    </div>
                </div>
                <div className="form-group col">
                    <label htmlFor="plantHc" className="col-form-label"><span className="text-danger starCss">*</span>Plant
                    HC</label>
                    <div>
                        <input type="text" className="form-control" name="plantHc"
                               onChange={props.handleChange} value={props.formValue.plantHc} disabled/>
                    </div>
                </div>
                <div className="form-group col  ">
                    <label className="col-form-label" htmlFor="siteStatus"><span className="text-danger starCss">*</span>Site
                    Status</label>
                    <div>
                        <select onChange={props.handleChange} name="siteStatus"
                                className={`form-select ${!props.inputValidation.siteStatus ? "errorDiv" : ""}`}
                                value={props.formValue.siteStatus || "Closed" }>
                            <option value="select">Select</option>
                            <option value="Open">Active</option>
                            <option value="Closed">Inactive</option>
                        </select>
                    </div>
                </div>
            </Row>
            <Row>
                <div className="form-group col  ">
                    <label htmlFor="siteTitle" className="col-form-label"><span className="text-danger starCss">*</span>Site</label>
                    <div>
                        <input type="text" name="site"
                               onChange={props.handleChange}
                               className={`form-control ${!props.inputValidation.site ? "errorDiv" : ""}`}
                               value={props.formValue.site}
                               disabled={(!props.isAdminAccess && props.readOnlyField)}/>
                    </div>
                </div>
                <div className="form-group col  ">
                    <label htmlFor="siteId" className="col-form-label"><span className="text-danger starCss">*</span>Site
                    Id</label>
                    <div>
                        <input type="text" name="siteId" onChange={props.handleChange}
                               className={`form-control ${!props.inputValidation.siteId ? "errorDiv" : ""}`}
                               value={props.formValue.siteId} disabled={props.readOnlyField}/>
                    </div>
                </div>
                <div className="form-group col">
                    <label htmlFor="idn" className="col-form-label">Security Group</label>
                    <div ref={props.innerIdnRef} className="multiSelectField">
                        <input className="form-select"
                               type="text" onClick={props.handleClick} readOnly={true}
                               value={`${props.formValue.idnCount} selected`} name="idn"/>
                        {
                            props.formValue.showIdn &&
                            <div className="selectDiv text-start px-sm-1">
                                <MultiSelect show={props.formValue.showIdn} data={props.idnNewList}
                                             handleCheckboxChange={props.handleCheckboxChange}
                                             allSelected={props.formValue.selectAllIdn}
                                             label="idn" handleSearch={props.handleSearch}/>
                            </div>
                        }
                    </div>
                </div>
            </Row>
            <Row>
                <div className="form-group col  ">
                    <label htmlFor="division" className="col-form-label"><span className="text-danger starCss">*</span>CLS Division</label>
                    <div>
                        <input type="text" name="division"
                               onChange={props.handleChange}
                               className={`form-control ${!props.inputValidation.division ? "errorDiv" : ""}`}
                               value={props.formValue.division}/>
                    </div>
                </div>
                <div className="form-group col  ">
                    <label htmlFor="region" className="col-form-label"><span className="text-danger starCss">*</span>CLS Region</label>
                    <div>
                        <input type="text" name="region"
                               onChange={props.handleChange}
                               className={`form-control ${!props.inputValidation.region ? "errorDiv" : ""}`}
                               value={props.formValue.region}/>
                    </div>
                </div>
                <div className="form-group col  ">
                    <label htmlFor="sam" className="col-form-label"><span className="text-danger starCss">*</span>Sam</label>
                    <div>
                        <select onChange={props.handleChange} name="sam"
                                className={`form-select ${!props.inputValidation.sam ? "errorDiv" : ""}`}
                              value={props.formValue.sam}    >
                            <option value="select">Select</option>
                            {
                                props.samList && props.samList.length
                                && props.samList.map((obj, index) => {
                                    return <option key={obj.value + index}
                                                   value={obj.value}>{obj.value}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
            </Row>
            <Row>
                <div className="form-group col  ">
                    <label htmlFor="annualVolume" className="col-form-label"><span className="text-danger starCss">*</span>Annual
                        Volume</label>
                    <div>
                    {validVolumes.includes(props.formValue.annualVolume)? <select onChange={handleAnnualVolumeChange} name="annualVolume"
                                className={`form-select ${!props.inputValidation.annualVolume ? "errorDiv" : ""}`}
                                value={props.formValue.annualVolume} >
                            <option value="select">Select</option>
                                  <option value=">3M"> &gt;3M </option>
                                <option value="1M - 3M">1M - 3M</option>
                                <option value="500K - 999K">500K - 999K</option>
                                <option value="100K - 499K">100K - 499K</option>
                                <option value="&lt;100K">&lt;100K</option>
                        </select>:  <input type="text" name="annualVolume"
                               onChange={props.handleChange}
                               className={`form-control ${!props.inputValidation.annualVolume ? "errorDiv" : ""}`}
                               value={props.formValue.annualVolume}/>}
                    </div>
                </div>
                <div className="form-group col  ">
                    <label htmlFor="visitFrequency" className="col-form-label"><span className="text-danger starCss">*</span>Visit
                        Frequency</label>
                    <div>
                        <select onChange={props.handleChange} name="visitFrequency"
                                className={`form-select ${!props.inputValidation.visitFrequency ? "errorDiv" : ""}`}
                                value={props.formValue.visitFrequency} >
                            <option value="select">Select</option>
                            {
                                props.visitFrequencyList && props.visitFrequencyList.length
                                && props.visitFrequencyList.map((obj, index) => {
                                    return <option key={obj.value + index}
                                                   value={obj.value}>{obj.value}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="form-group col  ">
                    <label className="col-form-label" htmlFor="requiredQBRs"><span className="text-danger starCss">*</span>Required QBRs</label>
                    <div>
                        <input type="number" name="requiredQBRs"
                               onChange={props.handleChange}
                               className={`form-control ${!props.inputValidation.requiredQBRs ? "errorDiv" : ""}`}
                               value={props.formValue.requiredQBRs} key={Date.now()}/>
                    </div>
                </div>
            </Row>
            <Row>
                <div className="form-group col  ">
                    <label htmlFor="requiredCV" className="col-form-label"><span className="text-danger starCss">*</span>Required CV</label>
                    <div>
                        <input type="number" name="requiredCV"
                               onChange={props.handleChange}
                               className={`form-control ${!props.inputValidation.requiredCV ? "errorDiv" : ""}`}
                               value={props.formValue.requiredCV} key={Date.now()}/>
                    </div>
                </div>
                <div className="form-group col  ">
                    <label htmlFor="requiredCS" className="col-form-label"><span className="text-danger starCss">*</span>Required CS</label>
                    <div>
                        <input type="number" name="requiredCS"
                               onChange={props.handleChange}
                               className={`form-control ${!props.inputValidation.requiredCS ? "errorDiv" : ""}`}
                               value={props.formValue.requiredCS} key={Date.now()}/>
                    </div>
                </div>
                <div className="form-group col  ">
                    <label className="col-form-label" htmlFor="requiredEU"><span className="text-danger starCss">*</span>Required EU</label>
                    <div>
                        <input type="number" name="requiredEU"
                               onChange={props.handleChange}
                               className={`form-control ${!props.inputValidation.requiredEU ? "errorDiv" : ""}`}
                               value={props.formValue.requiredEU} key={Date.now()}/>
                    </div>
                </div>
            </Row>
            <Row>
                <div className="form-group col  ">
                    <label htmlFor="requiredQA" className="col-form-label"><span className="text-danger starCss">*</span>Required QA</label>
                    <div>
                        <input type="number" name="requiredQA"
                               onChange={props.handleChange}
                               className={`form-control ${!props.inputValidation.requiredQA ? "errorDiv" : ""}`}
                               value={props.formValue.requiredQA} key={Date.now()}/>
                    </div>
                </div>
                <div className="form-group col  ">
                    <label htmlFor="requiredPAR" className="col-form-label"><span className="text-danger starCss">*</span>Required PAR</label>
                    <div>
                        <input type="number" name="requiredPAR"
                               onChange={props.handleChange}
                               className={`form-control ${!props.inputValidation.requiredPAR ? "errorDiv" : ""}`}
                               value={props.formValue.requiredPAR} key={Date.now()}/>
                    </div>
                </div>
                <div className="form-group col  ">
                    <label className="col-form-label" htmlFor="requiredLL"><span className="text-danger starCss">*</span>Required LL</label>
                    <div>
                        <input type="number" name="requiredLL"
                               onChange={props.handleChange}
                               className={`form-control ${!props.inputValidation.requiredLL ? "errorDiv" : ""}`}
                               value={props.formValue.requiredLL} key={Date.now()}/>
                    </div>
                </div>
            </Row>
            <Row>
                <div className="form-group col  ">
                    <label htmlFor="LArequiredCommittee" className="col-form-label"><span className="text-danger starCss">*</span>LA Required
                        Committee</label>
                    <div>
                        <input type="number" name="LArequiredCommittee"
                               onChange={props.handleChange}
                               className={`form-control ${!props.inputValidation.LArequiredCommittee ? "errorDiv" : ""}`}
                               value={props.formValue.LArequiredCommittee} key={Date.now()}/>
                    </div>
                </div>
                <div className="form-group col  ">
                    <label className="col-form-label" htmlFor="LCrequiredCommittee"><span className="text-danger starCss">*</span>LC Required
                        Committee</label>
                    <div>
                        <input type="number" name="LCrequiredCommittee"
                               onChange={props.handleChange}
                               className={`form-control ${!props.inputValidation.LCrequiredCommittee ? "errorDiv" : ""}`}
                               value={props.formValue.LCrequiredCommittee} key={Date.now()}/>
                    </div>
                </div>
                <div className="form-group col  ">
                    <label htmlFor="addOnSales" className="col-form-label"><span className="text-danger starCss">*</span>Add On Sales</label>
                    <div>
                        <input type="number" name="addOnSales"
                               onChange={props.handleChange}
                               className={`form-control ${!props.inputValidation.addOnSales ? "errorDiv" : ""}` }
                               value={props.formValue.addOnSales} key={Date.now()}/>
                    </div>
                </div>
            </Row>
            <Row>
                <div className="form-group col  ">
                    <label htmlFor="dvp" className="col-form-label"><span className="text-danger starCss">*</span>DVP</label>
                    <div>
                        <select onChange={props.handleChange} name="dvp"
                                className={`form-select ${!props.inputValidation.dvp ? "errorDiv" : ""}`}
                                value={props.formValue.dvp}>
                            <option value="select">Select</option>
                            {
                                props.dvpList && props.dvpList.length
                                && props.dvpList.map((obj, index) => {
                                    return <option key={obj.value + index}
                                                   value={obj.value}>{obj.value}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="form-group col  ">
                    <label className="col-form-label" htmlFor="rm"><span className="text-danger starCss">*</span>RDO</label>
                    <div>
                        <select onChange={props.handleChange} name="rm"
                                className={`form-select ${!props.inputValidation.rm ? "errorDiv" : ""}`}
                                value={props.formValue.rm}>
                            <option value="select">Select</option>
                            {
                                props.rdoList && props.rdoList.length
                                && props.rdoList.map((obj, index) => {
                                    return <option key={obj.value + index}
                                                   value={obj.value}>{obj.value}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="form-group col  ">
                    <label htmlFor="gm" className="col-form-label"><span className="text-danger starCss">*</span>GM</label>
                    <div>
                        <select onChange={props.handleChange} name="gm"
                                className={`form-select ${!props.inputValidation.gm ? "errorDiv" : ""}`}
                                value={props.formValue.gm}>
                            <option value="select">Select</option>
                            {
                                props.gmList && props.gmList.length
                                && props.gmList.map((obj, index) => {
                                    return <option key={obj.value + index}
                                                   value={obj.value}>{obj.value}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
            </Row>
            <Row>
                <div className="form-group col  ">
                    <label htmlFor="traumaLevel" className="col-form-label">Trauma Level</label>
                    <div>
                        <select value={props.formValue.traumaLevel} onChange={props.handleChange}
                                name="traumaLevel"
                                className="form-select">
                            <option value="select">Select</option>
                            {
                                props.traumaLevelList && props.traumaLevelList.length
                                && props.traumaLevelList.map((obj, index) => {
                                    return <option key={obj.value + index}
                                                   value={obj.value}>{obj.value}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
            </Row>
            <Row>
                <div className="form-group col">Contact</div>
                <div className="form-group col">Name</div>
                <div className="form-group col">Email</div>
                <div className="form-group col">Phone No.</div>
            </Row>
            <Row>
                <div className="form-group col">
                    <label htmlFor="monday" className="col-form-label">Contact 1</label>
                </div>
                <Col>
                    <div>
                        <input type="text" onChange={props.handleChange}
                               className="form-control"
                               value={props.formValue.contactName1} name="contactName1"/>
                    </div>
                </Col>
                <Col>
                    <div>
                        <input type="email" onChange={props.handleChange}
                               className="form-control"
                               value={props.formValue.contactEmail1} name="contactEmail1"/>
                    </div>
                </Col>
                <Col>
                    <div>
                        <input type="number" onChange={props.handleChange}
                               className="form-control"
                               value={props.formValue.contactPhone1} name="contactPhone1"/>
                    </div>
                </Col>
            </Row>
            <Row>
                <div className="form-group col">
                    <label htmlFor="monday" className="col-form-label">Contact 2</label>
                </div>
                <Col>
                    <div>
                        <input type="text" onChange={props.handleChange}
                               className="form-control"
                               value={props.formValue.contactName2} name="contactName2"/>
                    </div>
                </Col>
                <Col>
                    <div>
                        <input type="email" onChange={props.handleChange}
                               className="form-control"
                               value={props.formValue.contactEmail2} name="contactEmail2"/>
                    </div>
                </Col>
                <Col>
                    <div>
                        <input type="number" onChange={props.handleChange}
                               className="form-control"
                               value={props.formValue.contactPhone2} name="contactPhone2"/>
                    </div>
                </Col>
            </Row>
            <Row>
                <div className="form-group col">
                    <label htmlFor="monday" className="col-form-label">Contact 3</label>
                </div>
                <Col>
                    <div>
                        <input type="text" onChange={props.handleChange}
                               className="form-control"
                               value={props.formValue.contactName3} name="contactName3"/>
                    </div>
                </Col>
                <Col>
                    <div>
                        <input type="email" onChange={props.handleChange}
                               className="form-control"
                               value={props.formValue.contactEmail3} name="contactEmail3"/>
                    </div>
                </Col>
                <Col>
                    <div>
                        <input type="number" onChange={props.handleChange}
                               className="form-control"
                               value={props.formValue.contactPhone3} name="contactPhone3"/>
                    </div>
                </Col>
            </Row>
            <Row>
                <div className="form-group col">
                    <label htmlFor="monday" className="col-form-label">Contact 4</label>
                </div>
                <Col>
                    <div>
                        <input type="text" onChange={props.handleChange}
                               className="form-control"
                               value={props.formValue.contactName4} name="contactName4"/>
                    </div>
                </Col>
                <Col>
                    <div>
                        <input type="email" onChange={props.handleChange}
                               className="form-control"
                               value={props.formValue.contactEmail4} name="contactEmail4"/>
                    </div>
                </Col>
                <Col>
                    <div>
                        <input type="number" onChange={props.handleChange}
                               className="form-control"
                               value={props.formValue.contactPhone4} name="contactPhone4"/>
                    </div>
                </Col>
            </Row>
            <Row>
                <div className="form-group col">
                    <label htmlFor="monday" className="col-form-label">Contact 5</label>
                </div>
                <Col>
                    <div>
                        <input type="text" onChange={props.handleChange}
                               className="form-control"
                               value={props.formValue.contactName5} name="contactName5"/>
                    </div>
                </Col>
                <Col>
                    <div>
                        <input type="email" onChange={props.handleChange}
                               className="form-control"
                               value={props.formValue.contactEmail5} name="contactEmail5"/>
                    </div>
                </Col>
                <Col>
                    <div>
                        <input type="number" onChange={props.handleChange}
                               className="form-control" value={props.formValue.contactPhone5} name="contactPhone5"/>
                    </div>
                </Col>
            </Row>
            <div className="row mx-auto form">
                <Col>
                    <Button className="buttonCss formButton" type="submit" disabled={props.disableSubmit}>{props.buttonTitle}</Button>
                </Col>
                <Col>
                    <Button className="buttonCss formButton" onClick={props.cancelHandler}>Reset</Button>
                </Col>
            </div>
        </form>
    )
}

export default SiteForm;
